.language-container{
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.language-select{
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
}
