.btn-whitelabel {
  color: #ffffff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.1em;
}

.btn-whitelabel:hover,
.btn-whitelabel:focus,
.btn-whitelabel:active,
.btn-whitelabel.active,
.open .dropdown-toggle.btn-whitelabel {
  color: #ffffff;
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-whitelabel:active,
.btn-whitelabel.active,
.open .dropdown-toggle.btn-whitelabel {
  background-image: none;
}

.btn-whitelabel.disabled,
.btn-whitelabel[disabled],
fieldset[disabled] .btn-whitelabel,
.btn-whitelabel.disabled:hover,
.btn-whitelabel[disabled]:hover,
fieldset[disabled] .btn-whitelabel:hover,
.btn-whitelabel.disabled:focus,
.btn-whitelabel[disabled]:focus,
fieldset[disabled] .btn-whitelabel:focus,
.btn-whitelabel.disabled:active,
.btn-whitelabel[disabled]:active,
fieldset[disabled] .btn-whitelabel:active,
.btn-whitelabel.disabled.active,
.btn-whitelabel[disabled].active,
fieldset[disabled] .btn-whitelabel.active {
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-whitelabel .badge {
  color: $primary-color;
  background-color: #ffffff;
} //secondary
.btn-whitelabel-secondary {
  text-transform: uppercase;
  font-weight: 800;
  color: #ffffff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.btn-whitelabel-secondary:hover,
.btn-whitelabel-secondary:focus,
.btn-whitelabel-secondary:active,
.btn-whitelabel-secondary.active,
.open .dropdown-toggle.btn-whitelabel-secondary {
  color: #ffffff;
  background-color: $secondary-color-hover;
  border-color: $secondary-color;
}

.btn-whitelabel-secondary:active,
.btn-whitelabel-secondary.active,
.open .dropdown-toggle.btn-whitelabel-secondary {
  background-image: none;
}

.btn-whitelabel-secondary.disabled,
.btn-whitelabel-secondary[disabled],
fieldset[disabled] .btn-whitelabel-secondary,
.btn-whitelabel-secondary.disabled:hover,
.btn-whitelabel-secondary[disabled]:hover,
fieldset[disabled] .btn-whitelabel-secondary:hover,
.btn-whitelabel-secondary.disabled:focus,
.btn-whitelabel-secondary[disabled]:focus,
fieldset[disabled] .btn-whitelabel-secondary:focus,
.btn-whitelabel-secondary.disabled:active,
.btn-whitelabel-secondary[disabled]:active,
fieldset[disabled] .btn-whitelabel-secondary:active,
.btn-whitelabel-secondary.disabled.active,
.btn-whitelabel-secondary[disabled].active,
fieldset[disabled] .btn-whitelabel-secondary.active {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.btn-whitelabel-secondary .badge {
  color: $secondary-color;
  background-color: #ffffff;
}

//auxiliar
.btn-whitelabel-auxiliar {
  text-transform: uppercase;
  font-weight: 800;
  color: $primary-color;
  background-color: #ffffff;
  border-color: #929292;
}

.btn-whitelabel-auxiliar:hover,
.btn-whitelabel-auxiliar:focus,
.btn-whitelabel-auxiliar:active,
.btn-whitelabel-auxiliar.active,
.open .dropdown-toggle.btn-whitelabel-auxiliar {
  color: $primary-color;
  background-color: #ffffff;
  border-color: #929292;
}

.btn-whitelabel-auxiliar:active,
.btn-whitelabel-auxiliar.active,
.open .dropdown-toggle.btn-whitelabel-auxiliar {
  background-image: none;
}

.btn-whitelabel-auxiliar.disabled,
.btn-whitelabel-auxiliar[disabled],
fieldset[disabled] .btn-whitelabel-auxiliar,
.btn-whitelabel-auxiliar.disabled:hover,
.btn-whitelabel-auxiliar[disabled]:hover,
fieldset[disabled] .btn-whitelabel-auxiliar:hover,
.btn-whitelabel-auxiliar.disabled:focus,
.btn-whitelabel-auxiliar[disabled]:focus,
fieldset[disabled] .btn-whitelabel-auxiliar:focus,
.btn-whitelabel-auxiliar.disabled:active,
.btn-whitelabel-auxiliar[disabled]:active,
fieldset[disabled] .btn-whitelabel-auxiliar:active,
.btn-whitelabel-auxiliar.disabled.active,
.btn-whitelabel-auxiliar[disabled].active,
fieldset[disabled] .btn-whitelabel-auxiliar.active {
  background-color: #ffffff;
  border-color: $secondary-color;
}

.btn-whitelabel-auxiliar .badge {
  color: $secondary-color;
  background-color: #ffffff;
}

.dropdown-item{
  white-space: break-spaces !important;
  word-break: break-all;
}
