/* Sticky footer styles
-------------------------------------------------- */
html, body {
  height: 100%;
}

html {
  position: relative;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  background-image: none;
}

body {
  background-size: cover;
  background-position: center;
  font-family: 'Open Sans', sans-serif;
}

.background-app {
  margin-bottom: 0px;
  background-image: none;
}

.overload {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.507);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  left: 0;
  top: 0;
}

.footer {
  position: relative;
  margin-top: 10px;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  @media (min-width: 576px) {
    height: 40px;
    line-height: 30px;
    position: absolute;
    margin-top: 0px;
    bottom: -10px;
    /* Vertically center the text there */
  }
}

.field-error-message {
  color: red;
  padding-bottom: 10px;
}

/* Custom page CSS
  -------------------------------------------------- */

/* Not required for template or sticky footer method. */

body > .container {
  padding: 40px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

.navbar-toggler {
  margin-right: 5px;
}

.main-body {
  .sidebar {
    position: fixed;
    top: 100px;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0;
    //overflow-y: scroll;
  }
}

.loader-item {
  svg {
    max-height: 100%;
  }
}

.content-loader {
  align-items: center;
  justify-content: center;
}

.content-no-items {
  width: 100%;
  padding-top: 100px;
  p {
    svg {
      font-size: 100px;
    }
    text-align: center;
  }
  color: #999;
  font-weight: 300;
  align-items: center;
  justify-content: center;
}

//custom scroll

.custom-scroll {
  min-height: 0;
  min-width: 0;
}

.custom-scroll .outer-container {
  overflow: hidden;
  position: relative;
}

.custom-scroll .outer-container:hover .custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.custom-scroll .inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-scroll .inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, transparent 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.custom-scroll .inner-container.content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.custom-scroll.scroll-handle-dragged .inner-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-scroll .custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.custom-scroll .custom-scrollbar.custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.custom-scroll.scroll-handle-dragged .custom-scrollbar {
  opacity: 1;
}

.custom-scroll .custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.custom-scroll .inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: #ddd;
  border-radius: 3px;
}

//scrollbars
.scrollbars-y {
  div:first-child {

    overflow-x: hidden !important;

  }

}

.modal {
  .item{
    padding: 0px;
  }
  button.close{
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 2rem;
    color: white;
  }
  .modal-header {
    border-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    .close {
      padding: 10px 1rem;
      span {
        font-size: 30px;
      }
    }
  }
  .modal-body {
    padding-top: 0px;
    .slick-slider {
      margin-top: 0px;
    }
    .banner {
      figure {

        height: 350px;
        @media (min-width: 576px) {
          height: 400px;
        }
        @media (min-width: 768px) {
          height: 250px;
        }
        @media (min-width: 992px) {
          height: 450px;
        }
        @media (min-width: 1200px) {
          height: 450px;
        }
        @media (min-width: 1500px) {
          height: 450px;
        }
      }

    }
  }
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

.left-container{
  margin-left: unset;
}
.no-row-margin{
  .row{
    margin: 0px;
  }
}

.title-section {
  color: #333333;
  font-weight: 700;
  padding-top: 25px;
  span{
    text-transform: uppercase;
  }
}
.bold{
  font-weight: 800;
}
.text-with-span span {
  font-weight: 700;
}
.line{
  width: 100%;
  height: 1px;
  background: #d6d6d6;
  margin: 15px 0px;
}
.main-header .navbar-brand{
  text-align: justify;
}
.field-error-message{
  font-weight: 200;
  font-size: 0.9em;
}
